import React from "react";
import { Flex, Img, Text, useMediaQuery, Box } from "@chakra-ui/react";
import GENOMESDAO from "../../asset/genomesdaologo.png";
import MobileNavDrawer from "./MobileNavDrawer";
import GIT from "../../asset/smallgit.png";
import ROADMAP from "../../asset/road.png";
import INCLUDED from "../../asset/included.png";

export const scroll = () => {
  const anchor = document.querySelector("#number1");
  anchor.scrollIntoView({ behavior: "smooth", block: "center" });
};
const Navbar = ({ ref, click, roadmapclick, faqsref, worksref }) => {
  const [isMobileDevice] = useMediaQuery("(max-width: 991px)");
  const [isSmallScreen] = useMediaQuery("(max-width: 1500px)");
  const [isNotMobile] = useMediaQuery("(min-width: 991px)");

  return (
    <React.Fragment ref={ref}>
      {isMobileDevice ? (
        <Flex px={8} py={4}>
          <Flex
            w='100%'
            alignItems='center'
            justifyContent='space-between'
            h='10'
          >
            <Box>
              <Img h='24px' mt={-2} src={GENOMESDAO} />
            </Box>
            <MobileNavDrawer
              roadmapclick={roadmapclick}
              click={click}
              faqsref={faqsref}
              worksref={worksref}
            />
          </Flex>
        </Flex>
      ) : (
        <Flex
          alignItems='center'
          mx={isMobileDevice ? "" : isSmallScreen && isNotMobile ? 40 : 80}
          justifyContent='space-between'
          py={6}
        >
          <Img h='34px' src={GENOMESDAO} />
          <Flex
            fontFamily='Helvetica-Light'
            fontSize='14px'
            color='#5B01AA'
            justifyContent='space-between'
          >
            <Flex alignItems='center'>
              <Img w='16px' mr={1} src={INCLUDED} />
              <Text mr={4} cursor='pointer' onClick={click}>
                What's Included
              </Text>
            </Flex>

            <Flex alignItems='center'>
              <Img w='16px' mr={1} src={GIT} />
              <Text mr={4} cursor='pointer' onClick={worksref}>
                How It Works
              </Text>
            </Flex>

            <Flex alignItems='center'>
              <Img w='16px' mr={1} src={ROADMAP} />
              <Text mr={4} cursor='pointer' onClick={roadmapclick}>
                Roadmap
              </Text>
            </Flex>
            <Text mr={4} cursor='pointer' onClick={faqsref}>
              FAQs
            </Text>
            <Text cursor='pointer'>
              <a href='https://genomes.io/'>Genomes.io</a>
            </Text>
          </Flex>
        </Flex>
      )}
    </React.Fragment>
  );
};

export default Navbar;
