import React, { useState, forwardRef } from "react";
import {
  Flex,
  Text,
  Img,
  Icon,
  Collapse,
  useMediaQuery,
} from "@chakra-ui/react";
import "../Bundles/Bundles.css";
import DROPDOWN from "../../asset/dropdown.png";
import { RiArrowDropDownLine } from "react-icons/ri";

const Faqs = forwardRef((props, ref) => {
  const [active, setActive] = useState(false);
  const [isMobileDevice] = useMediaQuery("(max-width: 991px)");
  const [isSmallScreen] = useMediaQuery("(max-width: 1500px)");
  const [isNotMobile] = useMediaQuery("(min-width: 991px)");

  const handleView = (compname) => {
    if (active === compname) {
      console.log("here1");
      setActive("");
    } else {
      console.log("here");
      setActive(compname);
    }
  };

  console.log(active);

  return (
    <Flex
      mx={isMobileDevice ? 10 : isSmallScreen && isNotMobile ? 40 : 80}
      mt={isMobileDevice ? 20 : 40}
      flexDirection='column'
      ref={ref}
    >
      <Flex justifyContent={isMobileDevice ? "flex-start" : "flex-start"}>
        <Text
          mb={10}
          // ml={isMobileDevice ? "" : 20}
          className={isMobileDevice ? "mobile-heading" : "heading"}
          fontFamily='Helvetica-Bd'
          fontSize={isMobileDevice ? "40px" : "80px"}
        >
          FAQs
        </Text>
      </Flex>
      <Flex
        fontSize='15px'
        fontFamily='Helvetica-Md'
        // ml={isMobileDevice ? "" : 20}
        flexDirection='column'
      >
        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>
            What is GenomesDAO? I've never heard of it...
          </Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            onClick={() => handleView("first")}
            as={RiArrowDropDownLine}
          />
        </Flex>
        <Collapse animateOpacity in={active === "first"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            GenomesDAO is a biotech DAO focused on the safe, private, and
            auditable monetization of genomic data. We’re merging the bleeding
            edge of encryption technology (AMD-SEV Vaults) with the blockchain
            to create the world’s first decentralized data brokering platform
            where users have full-custody and control of their genomic
            information. We’re a Consensys Tachyon Accelerator Alum (the same
            company that created metamask!)
          </Text>
        </Collapse>
        <hr />
        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>When is the mint date?</Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("second")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "second"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            February 28th, 2022
          </Text>
        </Collapse>
        <hr />
        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>
            How long does it take to get my genome sequenced and uploaded to my
            AMD Genome Mining Vault?
          </Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("third")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "third"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            14-16 weeks
          </Text>
        </Collapse>
        <hr />
        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>
            Is this vaporware? Do you have a working product?
          </Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("fourth")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "fourth"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            Good question! No, it’s not vaporware, we have genomes in vaults and
            our blockchain wallet live in the app store. Here’s more detailed
            information about how the vault process{" "}
            <a
              style={{ color: "blue", textDecoration: "underline" }}
              href='https://genomes.io/assets/uploads/Activate.KIT.PDF.Tutorial.pdf'
            >
              works
            </a>
          </Text>
        </Collapse>
        <hr />
        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>
            What’s “Genome Mining”? Is it safe? How is my data protected?
          </Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("fifth")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "fifth"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            By using the AMD powered Genome Mining Vaults, we’ve created a
            paradigm shift where you have full custody of your genome, but can
            choose to safely and privately monetize your data by allowing 3rd
            parties or pharmaceutical companies to query parts of your genome in
            exchange for GENE tokens.
          </Text>
        </Collapse>
        <hr />
        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>
            What are the available traits that come with each NFT?
          </Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("sixth")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "sixth"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            Each Geneticats NFT comes with a whole genome sequencing kit that
            can be redeemed only once. Certain Geneticats come with extra traits
            such as GENE or GNOME token redemptions.
          </Text>
        </Collapse>
        <hr />
        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>
            What will the Geneticat NFT be worth on the secondary sale?
          </Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("seventh")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "seventh"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            The Geneticat NFT loses the kit redemption utility after the first
            kit has been redeemed. However, after your genome is sequenced, the
            1:1 generative NFT derived from your genome is intended to be an NFT
            that you HODL. This NFT represents a part of you being immortalized
            on the blockchain forever, why would you ever want to sell it?
          </Text>
        </Collapse>
        <hr />
        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>
            What happens if I buy more than 1 NFT? Can I send one NFT as a gift?
          </Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("eigth")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "eigth"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            Yes! Once minted you can send the NFT to someone else. Keep in mind
            that the whole genome sequencing kit can be redeemed only once.
          </Text>
        </Collapse>
        <hr />
        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>
            For the 1:1 Generative NFT – parts of my genome are queried. Is this
            safe?
            <br /> Could my privacy be compromised?
          </Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("ninth")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "ninth"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            We plan to query a very tiny part of the 3 billion base pairs that
            make up your genome to generate this NFT. No one will be able to
            extrapolate any insights into your genomic profile by having this
            NFT in your wallet.
          </Text>
        </Collapse>
        <hr />
        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>
            What CC0 license is the Geneticat NFT under?
          </Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("tenth")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "tenth"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            Attribution 4.0 International. This license requires that reusers
            give credit to the creator. It allows reusers to distribute, remix,
            adapt, and build upon the material in any medium or format, even for
            commercial purposes.
          </Text>
        </Collapse>
        <hr />
        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>What's the mint price?</Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("eleventh")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "eleventh"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            Non-whitelist mint price was ~0.165 ETH. The whitelist mint price
            was be ~0.15 ETH.
          </Text>
        </Collapse>
        <hr />
        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>
            Is there a whitelist? Benefits for being on the whitelist?
          </Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("twelveth")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "twelveth"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            If you’re on the whitelist you’ll receive 10% off the price of the
            mint.
          </Text>
        </Collapse>
        <hr />

        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>
            Can the whole genome sequencing kit be shipped anywhere in the
            world?
          </Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("thirteenth")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "thirteenth"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            Yes
          </Text>
        </Collapse>
        <hr />

        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>How many Geneticats exist?</Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("fourteenth")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "fourteenth"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            There are only 975 Geneticats in existence.
          </Text>
        </Collapse>
        <hr />

        <hr />
        <Flex mb={5} mt={5} justifyContent='space-between'>
          <Text fontSize='18px'>
            This project sounds really cool, but it’s a bit complex. I still
            have many more questions! <br /> Where can I talk to someone?
          </Text>
          <Icon
            cursor='pointer'
            color='#4E00A8'
            w='40px'
            h='30px'
            as={RiArrowDropDownLine}
            onClick={() => handleView("fifteenth")}
          />
        </Flex>
        <Collapse animateOpacity in={active === "fifteenth"}>
          <Text fontSize='16px' fontFamily='Helvetica-Lt' mb={5}>
            Hop in our very active discord! We’re happy to answer any questions
            you may have or join us in our weekly community call on Thursdays at
            12:30 PM EST/5:30 PM GMT. https://discord.com/invite/3DaD2na4XJ
          </Text>
        </Collapse>
        <hr />
      </Flex>
    </Flex>
  );
});

export default Faqs;
