import React, { useState } from "react";
import "./Welcome.css";
import {
  Flex,
  Text,
  Image,
  useMediaQuery,
  Box,
  Fade,
  Collapse,
} from "@chakra-ui/react";
import AMDLOGO from "../../asset/amdlogo.png";
import LOCKIMG from "../../asset/vault.png";
import WELCOMECATS from "../../asset/welcomecat.png";
import CATGROUP from "../../asset/catgroup.png";
import "../Bundles/Bundles.css";

const Welcome = () => {
  const [isMobileDevice] = useMediaQuery("(max-width: 991px)");
  const [isSmallScreen] = useMediaQuery("(max-width: 1500px)");
  const [isNotMobile] = useMediaQuery("(min-width: 991px)");
  const [hover, setHover] = useState(false);
  return (
    <Box
      // paddingTop={isMobileDevice ? '50px' : '100px'}
      className='welcome__container'
    >
      {isMobileDevice ? (
        <>
          <Flex mt={10} flexDirection='column' justifyContent='center'>
            <Text
              fontFamily='Helvetica-Bold'
              color='#5B01AA'
              fontSize='40px'
              className='mobile-heading'
              textAlign='center'
              mx={5}
            >
              GENETICATS
            </Text>
            <Text
              fontSize='15px'
              fontFamily='Helvetica-Bold'
              textAlign='center'
              mt={-3}
              fontStyle='italic'
              color='#3F0578'
              fontWeight='900'
            >
              (This was not just another Cat NFT project!)
            </Text>

            <Flex justifyContent='center'>
              <Image src={LOCKIMG} />
            </Flex>
            <Text
              fontSize='20px'
              textAlign='center'
              fontFamily='Helvetica-Bold'
              color='#8353E4'
            >
              Welcome To Your DNA Bank
            </Text>
            <Flex mb={10} alignItems='center' justifyContent='center'>
              <Text fontFamily='Helvetica-Bold' mr={2}>
                Powered by
              </Text>
              <Image w='80px' mt={-1} src={AMDLOGO} />
            </Flex>
            <Flex justifyContent='center'>
              <Image src={CATGROUP} />
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <Flex
            mt={10}
            mx={isMobileDevice ? "" : isSmallScreen && isNotMobile ? 40 : 80}
            justifyContent='space-between'
            alignItems='center'
          >
            <Flex flexDirection='column' justifyContent='center'>
              <Flex flexDirection='column'>
                <Text
                  fontFamily='Helvetica-Bold'
                  color='#5B01AA'
                  fontSize='80px'
                  className='heading'
                >
                  GENETICATS
                  <br />
                </Text>
                <Text
                  fontSize='24px'
                  fontFamily='Helvetica-Bold'
                  // textAlign='center'
                  mt={-5}
                  fontStyle='italic'
                  color='#3F0578'
                  fontWeight='900'
                >
                  (This was not just another Cat NFT project!)
                </Text>
              </Flex>
              <Flex flexDirection='column'>
                <Flex justifyContent='center'>
                  <Image w='550px' src={LOCKIMG} />
                </Flex>
                <Flex mr={5} flexDirection='column'>
                  <Text
                    fontSize='25px'
                    textAlign='center'
                    fontFamily='Helvetica-Bold'
                    color='#8353E4'
                  >
                    Welcome To Your DNA Bank
                  </Text>
                  <Flex mt={-2} justifyContent='center' alignItems='center'>
                    <Text fontFamily='Helvetica-Bold' fontWeight='900' mr={2}>
                      Powered by
                    </Text>
                    <Image w='50px' src={AMDLOGO} />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Image src={WELCOMECATS} w='550px' />
          </Flex>
        </>
      )}
    </Box>
  );
};

export default Welcome;
