import React from "react";
import { Flex, Text, Image, useMediaQuery } from "@chakra-ui/react";
import genomeslogo from "../../asset/genomeslogo.svg";
import facebook from "../../asset/facebook.svg";
import instagram from "../../asset/instagram.svg";
import twitter from "../../asset/twitter.svg";
import linkedin from "../../asset/linkedin.svg";
import discord from "../../asset/discord.svg";

const Main = () => {
  const [isMobileDevice] = useMediaQuery("(max-width: 991px)");
  return (
    <>
      {isMobileDevice ? (
        <Flex
          mb={isMobileDevice ? 60 : undefined}
          w='100%'
          h='300px'
          flexDirection='column'
        >
          <Image ml={8} w='150px' my={10} src={genomeslogo} />
          <Flex align='center' justifyContent='space-around'>
            <Flex
              // justifyContent="space-around"
              flexWrap='wrap'
              // flexDirection="row"
              flexDirection='column'
            >
              <Text fontWeight='bold' mb={4} color='#FF3D7F' fontSize='16px'>
                Quick Links
              </Text>

              <a href='https://genomes.io/how-it-works'>
                <Text mb={3} fontSize='14px'>
                  How It Works
                </Text>
              </a>
              <a href='https://genomes.io/about'>
                <Text mb={3} fontSize='14px'>
                  About Us
                </Text>
              </a>

              <a href='https://genomes.io/blog'>
                <Text mb={3} fontSize='14px'>
                  News
                </Text>
              </a>

              <a href='https://genomes.io/about#team-members'>
                <Text mb={3} fontSize='14px'>
                  Team
                </Text>
              </a>
            </Flex>
            <Flex flexDirection='column'>
              <Text color='#FF3D7F' fontWeight='bold' mb={4} fontSize='16px'>
                Get Started
              </Text>

              <a href='https://genomes.io/get-started'>
                <Text mb={3} fontSize='14px'>
                  Get Started
                </Text>
              </a>

              <a href='https://genomes.io/get-dna-sequenced'>
                <Text mb={3} fontSize='14px'>
                  Get DNA Sequenced
                </Text>
              </a>
            </Flex>
          </Flex>
          <Flex mt={6} justifyContent='space-around'>
            <Flex flexDirection='column'>
              <Text fontWeight='bold' mb={4} color='#FF3D7F' fontSize='16px'>
                Need Help
              </Text>

              <a href='https://genomes.io/contact'>
                <Text mb={3} fontSize='14px'>
                  Contact Us
                </Text>
              </a>

              <a href='https://genomes.io/faq'>
                <Text mb={3} fontSize='14px'>
                  FAQs
                </Text>
              </a>
              <a href='https://genomes.io/assets/uploads/Activate.KIT.PDF.Tutorial.pdf'>
                <Text
                  textDecoration='underline'
                  color='#38AB9F'
                  mb={3}
                  fontSize='14px'
                >
                  Kit Informations
                </Text>
              </a>
            </Flex>
            <Flex mr={8} flexDirection='column'>
              <Text mb={4} color='#FF3D7F' fontWeight='bold' fontSize='16px'>
                Social Media
              </Text>

              <a href='https://twitter.com/genomesdao'>
                <Flex alignItems='center'>
                  <Image mr={3} mt={-1} src={twitter} />
                  <Text
                    mt={1}
                    textAlign='center'
                    color='#38AB9F'
                    mb={3}
                    fontSize='14px'
                  >
                    Twitter
                  </Text>
                </Flex>
              </a>

              <a href='https://www.facebook.com/genomesio/'>
                <Flex alignItems='center'>
                  <Image mr={3} mt={-1} src={facebook} />
                  <Text
                    mt={1}
                    textAlign='center'
                    color='#38AB9F'
                    mb={3}
                    fontSize='14px'
                  >
                    Facebook
                  </Text>
                </Flex>
              </a>

              <a href='https://www.instagram.com/genomesio/'>
                <Flex alignItems='center'>
                  <Image mr={3} mt={-1} src={instagram} />
                  <Text
                    mt={1}
                    textAlign='center'
                    color='#38AB9F'
                    mb={3}
                    fontSize='14px'
                  >
                    Instagram
                  </Text>
                </Flex>
              </a>

              <a href='https://www.linkedin.com/company/genomes-io/'>
                <Flex alignItems='center'>
                  <Image mr={3} mt={-1} src={linkedin} />
                  <Text
                    mt={1}
                    textAlign='center'
                    color='#38AB9F'
                    mb={3}
                    fontSize='14px'
                  >
                    LinkedIn
                  </Text>
                </Flex>
              </a>

              <a href='https://discord.gg/3DaD2na4XJ'>
                <Flex alignItems='center'>
                  <Image mr={3} mt={-1} src={discord} />
                  <Text
                    mt={1}
                    textAlign='center'
                    color='#38AB9F'
                    mb={3}
                    fontSize='14px'
                  >
                    Discord
                  </Text>
                </Flex>
              </a>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex
          flexWrap='wrap'
          //   h='300px'
          // ml={20}
          mt={20}
          justifyContent='center'
          w='100%'
          flexDirection='row'
        >
          <Flex w='100%' justifyContent='space-between'>
            <Flex w='100%' justifyContent='space-between'>
              <Flex alignItems='flex-start'>
                <Image w='150px' src={genomeslogo} />
              </Flex>
              <Flex fontFamily='Helvetica-lt' flexDirection='column'>
                <Text
                  fontFamily='Helvetica-Roman'
                  mb={4}
                  color='#FF3D7F'
                  fontSize='16px'
                >
                  Quick Links
                </Text>
                <a href='https://genomes.io/how-it-works'>
                  <Text mb={3} fontSize='14px'>
                    How It Works
                  </Text>
                </a>
                <a href='https://genomes.io/about'>
                  <Text mb={3} fontSize='14px'>
                    About Us
                  </Text>
                </a>

                <a href='https://genomes.io/blog'>
                  <Text mb={3} fontSize='14px'>
                    News
                  </Text>
                </a>

                <a href='https://genomes.io/about#team-members'>
                  <Text mb={3} fontSize='14px'>
                    Team
                  </Text>
                </a>
              </Flex>
              <Flex fontFamily='Helvetica-lt' flexDirection='column'>
                <Text
                  color='#FF3D7F'
                  fontFamily='Helvetica-Roman'
                  mb={4}
                  fontSize='16px'
                >
                  Get Started
                </Text>

                <a href='https://genomes.io/get-started'>
                  <Text mb={3} fontSize='14px'>
                    Get Started
                  </Text>
                </a>

                <a href='https://genomes.io/get-dna-sequenced'>
                  <Text mb={3} fontSize='14px'>
                    Get DNA Sequenced
                  </Text>
                </a>
              </Flex>
              <Flex fontFamily='Helvetica-lt' flexDirection='column'>
                <Text
                  fontFamily='Helvetica-Roman'
                  mb={4}
                  color='#FF3D7F'
                  fontSize='16px'
                >
                  Need Help
                </Text>
                <a href='https://genomes.io/contact'>
                  <Text mb={3} fontSize='14px'>
                    Contact Us
                  </Text>
                </a>

                <a href='https://genomes.io/faq'>
                  <Text mb={3} fontSize='14px'>
                    FAQs
                  </Text>
                </a>
                <a href='https://genomes.io/assets/uploads/Activate.KIT.PDF.Tutorial.pdf'>
                  <Text
                    textDecoration='underline'
                    color='#38AB9F'
                    mb={3}
                    fontSize='14px'
                  >
                    Kit Informations
                  </Text>
                </a>
              </Flex>
              <Flex fontFamily='Helvetica-lt' flexDirection='column'>
                <Text
                  mb={4}
                  fontFamily='Helvetica-Roman'
                  color='#FF3D7F'
                  fontSize='16px'
                >
                  Social Media
                </Text>
                <a href='https://twitter.com/genomesdao'>
                  <Flex alignItems='center'>
                    <Image mr={3} mt={-1} src={twitter} />
                    <Text
                      mt={1}
                      textAlign='center'
                      color='#38AB9F'
                      mb={3}
                      fontSize='14px'
                    >
                      Twitter
                    </Text>
                  </Flex>
                </a>

                <a href='https://www.facebook.com/genomesio/'>
                  <Flex alignItems='center'>
                    <Image mr={3} mt={-1} src={facebook} />
                    <Text
                      mt={1}
                      textAlign='center'
                      color='#38AB9F'
                      mb={3}
                      fontSize='14px'
                    >
                      Facebook
                    </Text>
                  </Flex>
                </a>

                <a href='https://www.instagram.com/genomesio/'>
                  <Flex alignItems='center'>
                    <Image mr={3} mt={-1} src={instagram} />
                    <Text
                      mt={1}
                      textAlign='center'
                      color='#38AB9F'
                      mb={3}
                      fontSize='14px'
                    >
                      Instagram
                    </Text>
                  </Flex>
                </a>

                <a href='https://www.linkedin.com/company/genomes-io/'>
                  <Flex alignItems='center'>
                    <Image mr={3} mt={-1} src={linkedin} />
                    <Text
                      mt={1}
                      textAlign='center'
                      color='#38AB9F'
                      mb={3}
                      fontSize='14px'
                    >
                      LinkedIn
                    </Text>
                  </Flex>
                </a>

                <a href='https://discord.gg/3DaD2na4XJ'>
                  <Flex alignItems='center'>
                    <Image mr={3} mt={-1} src={discord} />
                    <Text
                      mt={1}
                      textAlign='center'
                      color='#38AB9F'
                      mb={3}
                      fontSize='14px'
                    >
                      Discord
                    </Text>
                  </Flex>
                </a>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default Main;
