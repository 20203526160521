import React, { useState } from "react";
import {
  Flex,
  Text,
  Img,
  useMediaQuery,
  Input,
  Button,
  Image,
} from "@chakra-ui/react";
import NEWSLETTER from "../../asset/newsletter.png";
import axios from "axios";
import { toast } from "react-toastify";
import Main from "./Main";

const Footer = () => {
  const [isMobileDevice] = useMediaQuery("(max-width: 991px)");
  const [isSmallScreen] = useMediaQuery("(max-width: 1500px)");
  const [isNotMobile] = useMediaQuery("(min-width: 991px)");
  const [input, setInput] = useState("");

  const subscribeNewsletter = async (input) => {
    if (input) {
      try {
        axios
          .post("https://api.genomes.io/newsletter", { email: input })
          .then((res) => {
            if (res.data.message) {
              toast.success(res.data.message);
              setInput("");
            } else if (res.data.error) {
              toast.error(res.data.error);
            }
          });
      } catch (err) {
        toast.error("An Error Occured, Please try again with another Email");
      }
    }
  };
  return (
    <Flex
      mt={20}
      mx={isMobileDevice ? "" : isSmallScreen && isNotMobile ? 40 : 80}
      bgColor='#fff'
      flexDirection='column'
    >
      <Flex justifyContent='space-between'>
        <Flex
          borderRadius='6px'
          w={isMobileDevice ? "100%" : ""}
          flexDirection='column'
          // ml={isMobileDevice ? "" : 20}
          justifyContent={isMobileDevice ? "flex-end" : undefined}
        >
          <Flex
            mb={5}
            mx={isMobileDevice ? 10 : ""}
            justifyContent='flex-start'
          >
            <Text fontSize='13px' color='##1A1A1A' fontFamily='Helvetica-Light'>
              Subscribe For Updates
            </Text>
          </Flex>
          <Flex
            flexDirection={isMobileDevice ? "column" : ""}
            justifyContent='space-between'
            mx={isMobileDevice ? 10 : ""}
            alignItems={isMobileDevice ? "flex-start" : "center"}
          >
            <Flex mb={5}>
              <Input
                bgColor='#fff'
                border='1px'
                borderColor='#787878'
                borderRadius='18px'
                placeholder='Email'
                color='#000'
                _placeholder={{ color: "#1A1A1A", fontSize: "14px" }}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                mr={4}
              />
              <Button
                bgColor='#FF3D7F'
                w='120px'
                py={4}
                px={16}
                fontSize='14px'
                borderRadius='18px'
                fontFamily='Helvetica-Light'
                color='#fff'
                _hover={{ bgColor: "none" }}
                _active={{ bgColor: "none" }}
                onClick={() => subscribeNewsletter(input)}
              >
                Send
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Flex display={isMobileDevice ? "none" : undefined}>
          <Img src={NEWSLETTER} />
        </Flex>
      </Flex>
      <Main />
      <Flex justifyContent='center' mt={isMobileDevice ? 10 : ""} py={6}>
        <Text fontSize='14px' fontFamily='Helvetica-Light' color='#1A1A1A'>
          Copyright 2022 genomes.io
        </Text>
      </Flex>

      {/* </Flex> */}
    </Flex>
  );
};

export default Footer;
