import "./App.css";
import { useRef, useState } from "react";
import Welcome from "./components/Welcome/Welcome";
import Bundles from "./components/Bundles/Bundles";
import Competition from "./components/Competition/Competition";

import Footer from "./components/Footer/Footer";

import Navbar from "./components/Nav/Navbar";
import Faqs from "./components/Faqs/Faqs";

function Nft() {
  const scrollToDiv = (ref) => {
    console.log(ref);
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const navbarref = useRef();
  const bundlesref = useRef();
  const roadmapref = useRef();
  const mobileroadmapref = useRef();
  const faqsref = useRef();
  const worksref = useRef();
  const [refs, setRefs] = useState([roadmapref, worksref]);

  return (
    <div className='App'>
      <Navbar
        ref={navbarref}
        roadmapclick={() => scrollToDiv(roadmapref)}
        mobileroadmapclick={() => scrollToDiv(mobileroadmapref)}
        click={() => scrollToDiv(bundlesref)}
        faqsref={() => scrollToDiv(faqsref)}
        worksref={() => scrollToDiv(worksref)}
      />
      <Welcome />
      {/* <div className='App__container'> */}
      <Bundles ref={bundlesref} />
      <Competition
        workref={worksref}
        ref={{
          ref1: roadmapref,
          ref2: worksref,
        }}
        mobileref={mobileroadmapref}
      />
      <Faqs ref={faqsref} />
      <Footer />
      {/* </div> */}
    </div>
  );
}

export default Nft;
