import React from "react";
import {
  Box,
  Drawer,
  Text,
  DrawerContent,
  DrawerBody,
  useDisclosure,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import "./Nav.css";

const MobileNavDrawer = ({ click, roadmapclick, faqsref, worksref }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const scroll = () => {
    const anchor = document.querySelector("#why-cats");
    anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    onClose();
  };

  return (
    <>
      {isOpen ? (
        <Box>
          <Icon
            cursor='pointer'
            color='#FF3D7F'
            w='26px'
            h='24px'
            as={AiOutlineClose}
          />
        </Box>
      ) : (
        <Box onClick={onOpen}>
          <Icon
            cursor='pointer'
            color='#FF3D7F'
            w='30px'
            h='30px'
            as={GiHamburgerMenu}
          />
        </Box>
      )}
      <Drawer placement='top' onClose={onClose} isOpen={isOpen}>
        <DrawerContent h='40vh' mt={16}>
          <DrawerBody bgColor='#fff'>
            <Flex
              ml={3}
              color='#000'
              fontFamily='Helvetica-Light'
              flexDirection='column'
            >
              <Text
                mt={6}
                mb={6}
                fontSize='16px'
                onClick={() => {
                  onClose();
                  click();
                }}
                _hover={{ color: "#19AA9B" }}
              >
                Whats Included
              </Text>
              <Text
                mb={6}
                fontSize='16px'
                onClick={() => {
                  onClose();
                  worksref();
                }}
                _hover={{ color: "#19AA9B" }}
              >
                How It Works
              </Text>
              <Text
                mb={6}
                fontSize='16px'
                onClick={() => {
                  onClose();
                  roadmapclick();
                }}
                _hover={{ color: "#19AA9B" }}
              >
                Roadmap
              </Text>
              <Text
                mb={6}
                fontSize='16px'
                onClick={() => {
                  onClose();
                  faqsref();
                }}
                _hover={{ color: "#19AA9B" }}
              >
                FAQs
              </Text>
              <Text mb={6} fontSize='16px' _hover={{ color: "#19AA9B" }}>
                <a href='https://genomes.io/'>Genomes.io</a>
              </Text>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileNavDrawer;
