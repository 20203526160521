import React, { forwardRef, useState } from "react";
import { Flex, Text, Img, useMediaQuery, Box, Fade } from "@chakra-ui/react";
import DNAMASK from "../../asset/dnamask.png";
import STEP1 from "../../asset/step1.png";
import STEP3 from "../../asset/step3.png";
import STEP4 from "../../asset/step4.png";
import STEP5 from "../../asset/step5.png";
import STEP6 from "../../asset/step6.png";
import STEP2 from "../../asset/Step2.png";
import ROADMAP from "../../asset/roadmap.png";
import MOBILEROADMAP from "../../asset/mobileroadmap.png";
import HOWITWORKS from "../../asset/howitworks.png";
import "../Bundles/Bundles.css";

const Competition = forwardRef((props, ref) => {
  const [isMobileDevice] = useMediaQuery("(max-width: 991px)");
  const [isSmallDevice] = useMediaQuery("(max-width: 750px)");
  const [isSmallScreen] = useMediaQuery("(max-width: 1500px)");
  const [isNotMobile] = useMediaQuery("(min-width: 991px)");
  const [hover, setHover] = useState(false);

  const { ref1, ref2 } = ref;

  return (
    <Flex
      mt={isMobileDevice ? "" : 40}
      mx={isMobileDevice ? "" : isSmallScreen && isNotMobile ? 40 : 80}
      flexDirection='column'
    >
      <Flex
        ref={props.workref}
        // mx={isMobileDevice ? "" : 20}
        w='100%'
        flexDirection='column'
      >
        <Flex
          ref={ref2}
          w='100%'
          alignItems='center'
          justifyContent={
            isMobileDevice
              ? "space-between"
              : isMobileDevice
              ? "center"
              : "flex-start"
          }
          px={isMobileDevice ? 10 : undefined}
          mb={isMobileDevice ? 10 : ""}
        >
          {isMobileDevice ? (
            <Text
              className={isMobileDevice ? "mobile-heading" : "heading"}
              fontSize={
                isSmallDevice ? "40px" : isMobileDevice ? "40px" : "60px"
              }
              fontFamily='Helvetica-Bd'
            >
              HOW DOES <br /> IT WORK<span className='text-nft'>?</span>
            </Text>
          ) : (
            <Text
              className={isMobileDevice ? "text-nft" : "heading"}
              fontSize={isMobileDevice ? "25px" : "80px"}
              fontFamily='Helvetica-Bd'
            >
              HOW DOES IT WORK<span className='text-nft'>?</span>
            </Text>
          )}
          <Img display={isMobileDevice ? undefined : "none"} src={HOWITWORKS} />
        </Flex>
        <Flex
          w='100%'
          justifyContent={isMobileDevice ? "center" : "space-evenly"}
        >
          <Img
            w='20%'
            display={isMobileDevice ? "none" : ""}
            mr={5}
            src={DNAMASK}
          />
          <Flex w='100%' flexDirection='column'>
            <Flex w='100%' mt={10} mb={10}>
              <Flex
                w='100%'
                justifyContent={"space-between"}
                // alignItems={isMobileDevice ? "center" : undefined}
                mx={isMobileDevice ? 10 : undefined}
              >
                <Flex
                  mr={isMobileDevice ? 10 : undefined}
                  flexDirection='column'
                >
                  <Text
                    fontSize='16px'
                    fontFamily='Helvetica-Roman'
                    w={isMobileDevice ? "" : "80%"}
                  >
                    <span
                      style={{
                        color: "#5B01AA",
                        fontFamily: "Helvetica-Bd",
                        fontSize: "22px",
                      }}
                    >
                      Step 1
                    </span>
                    <br />
                    Mint the{" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>
                      Geneticats NFT
                    </span>
                    (this is the first NFT you get). Each{" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>NFT</span>{" "}
                    comes with a standard trait for redemption of a whole genome
                    sequencing kit. Certain NFT’s will come with more than one
                    redeemable trait, such as a{" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>
                      GENE/GNOME
                    </span>{" "}
                    airdrop.
                  </Text>
                </Flex>
                <Img
                  // display={isMobileDevice ? "none" : ""}
                  mt={6}
                  // mr={6}
                  w={isMobileDevice ? "100px" : "70px"}
                  h={isMobileDevice ? "100px" : "70px"}
                  src={STEP1}
                />
              </Flex>
            </Flex>

            <Flex>
              <hr
                style={{
                  marginBottom: "30px",
                  width: isMobileDevice ? "100%" : "100%",
                  // marginLeft: isMobileDevice ? "" : "60px",
                }}
              />
            </Flex>

            <Flex w='100%' mb={10}>
              <Flex
                w='100%'
                justifyContent={"space-between"}
                alignItems={isMobileDevice ? "center" : undefined}
                mx={isMobileDevice ? 10 : undefined}
                // mr={isMobileDevice ? undefined : 10}
              >
                <Flex
                  mr={isMobileDevice ? 10 : undefined}
                  flexDirection='column'
                  // mr={isMobileDevice ? 6 : undefined}
                >
                  <Text
                    fontSize='16px'
                    fontFamily='Helvetica-Roman'
                    w={isMobileDevice ? "" : "80%"}
                  >
                    <span
                      style={{
                        color: "#5B01AA",
                        fontFamily: "Helvetica-Bd",
                        fontSize: "22px",
                      }}
                    >
                      Step 2
                    </span>{" "}
                    <br />
                    Redeem the trait for the sequencing kit through the{" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>
                      EtherCards
                    </span>{" "}
                    platform. Upon redemption, you are given a code that allows
                    claiming your kit at genomes.io.{" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>
                      Then, we send you the kit
                    </span>{" "}
                    (this can be done more anonymously by using a PO box,
                    workplace address, or somewhere else you would have the
                    ability to receive packages).
                  </Text>
                </Flex>
                <Img
                  mt={6}
                  // mr={6}
                  w={isMobileDevice ? "100px" : "70px"}
                  h={isMobileDevice ? "" : "100px"}
                  src={STEP2}
                />
              </Flex>
            </Flex>
            <Flex>
              <hr
                style={{
                  marginBottom: "30px",
                  width: isMobileDevice ? "100%" : "100%",
                  // marginLeft: isMobileDevice ? "" : "60px",
                }}
              />
            </Flex>
            <Flex w='100%' mb={10}>
              <Flex
                w='100%'
                justifyContent={isMobileDevice ? "center" : "space-between"}
                // mr={isMobileDevice ? undefined : 10}

                mx={isMobileDevice ? 10 : undefined}
              >
                <Flex
                  mr={isMobileDevice ? 10 : undefined}
                  flexDirection='column'
                >
                  <Text
                    fontSize='16px'
                    fontFamily='Helvetica-Roman'
                    w={isMobileDevice ? "" : "80%"}
                  >
                    <span
                      style={{
                        color: "#5B01AA",
                        fontFamily: "Helvetica-Bd",
                        fontSize: "22px",
                      }}
                    >
                      Step 3
                    </span>{" "}
                    <br />
                    <span style={{ fontFamily: "Helvetica-Bold" }}>
                      Activate
                    </span>{" "}
                    both your kit and{" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>
                      DNA vault at Genomes.io.
                    </span>{" "}
                    When activating your vault, you are given a 12-word seed
                    phrase. Please store your 12-word seed phrase in a safe
                    location, similar to how you would store a metamask or
                    ledger seed phrase.
                  </Text>
                </Flex>
                <Img
                  mt={6}
                  // mr={6}
                  w='70px'
                  h='70px'
                  src={STEP3}
                />
              </Flex>
            </Flex>
            <Flex mx={isMobileDevice ? 10 : undefined}>
              <hr
                style={{
                  marginBottom: "30px",
                  width: isMobileDevice ? "100%" : "100%",
                  // marginLeft: isMobileDevice ? "" : "60px",
                }}
              />
            </Flex>
            <Flex w='100%' mb={10}>
              <Flex
                w='100%'
                justifyContent={isMobileDevice ? "center" : "space-between"}
                // mr={isMobileDevice ? undefined : 10}
                mx={isMobileDevice ? 10 : undefined}
              >
                <Flex
                  mr={isMobileDevice ? 10 : undefined}
                  flexDirection='column'
                >
                  <Text
                    fontSize='16px'
                    fontFamily='Helvetica-Roman'
                    w={isMobileDevice ? "" : "80%"}
                  >
                    <span
                      style={{
                        color: "#5B01AA",
                        fontFamily: "Helvetica-Bd",
                        fontSize: "22px",
                      }}
                    >
                      Step 4
                    </span>{" "}
                    <br />
                    Send us your{" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>
                      biological sample.
                    </span>{" "}
                    We sequence your genome through one of our audited providers
                    (results typically returned within 12 - 14 weeks).
                  </Text>
                </Flex>
                <Img
                  mt={6}
                  // mr={6}
                  w='70px'
                  h='70px'
                  src={STEP4}
                />
              </Flex>
            </Flex>
            <Flex mx={isMobileDevice ? 10 : undefined}>
              <hr
                style={{
                  marginBottom: "30px",
                  width: isMobileDevice ? "100%" : "100%",
                  // marginLeft: isMobileDevice ? "" : "60px",
                }}
              />
            </Flex>
            <Flex w='100%' mb={10}>
              <Flex
                w='100%'
                justifyContent={"space-between"}
                mx={isMobileDevice ? 10 : undefined}
                // mr={isMobileDevice ? undefined : 10}
              >
                <Flex
                  mr={isMobileDevice ? 10 : undefined}
                  flexDirection='column'
                >
                  <Text
                    fontSize='16px'
                    fontFamily='Helvetica-Roman'
                    w={isMobileDevice ? "" : "80%"}
                  >
                    <span
                      style={{
                        color: "#5B01AA",
                        fontFamily: "Helvetica-Bd",
                        fontSize: "22px",
                      }}
                    >
                      Step 5
                    </span>{" "}
                    <br />
                    Utilize the platform!{" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>
                      Ancestry, carrier status,
                    </span>{" "}
                    and other reports are continually added to the platform.{" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>
                      Use the vault to mine your genome!
                    </span>{" "}
                    <br /> <br /> Choose to accept or reject genomic queries
                    from 3rd parties or pharmaceutical companies that pay you in{" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>GENE</span>{" "}
                    to anonymously query parts of your genome.
                  </Text>
                </Flex>
                <Img
                  mt={6}
                  // mr={6}
                  w='70px'
                  h='70px'
                  src={STEP5}
                />
              </Flex>
            </Flex>
            <Flex mx={isMobileDevice ? 10 : undefined}>
              <hr
                style={{
                  marginBottom: "30px",
                  width: isMobileDevice ? "100%" : "100%",
                  // marginLeft: isMobileDevice ? "" : "60px",
                }}
              />
            </Flex>
            <Flex w='100%' mb={20}>
              <Flex
                w='100%'
                justifyContent={isMobileDevice ? "center" : "space-between"}
                // mr={isMobileDevice ? undefined : 10}
                mx={isMobileDevice ? 10 : undefined}
              >
                <Flex
                  mr={isMobileDevice ? 10 : undefined}
                  flexDirection='column'
                >
                  <Text
                    fontSize='16px'
                    fontFamily='Helvetica-Roman'
                    w={isMobileDevice ? "" : "80%"}
                  >
                    <span
                      style={{
                        color: "#5B01AA",
                        fontFamily: "Helvetica-Bd",
                        fontSize: "22px",
                      }}
                    >
                      Step 6
                    </span>{" "}
                    <br />
                    Once your genome is vaulted, we also plan to give you a{" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>
                      1:1 generative art NFT
                    </span>{" "}
                    derived from specific queries of your genome.
                  </Text>
                </Flex>
                <Img
                  mt={6}
                  // mr={6}
                  w='70px'
                  h='70px'
                  src={STEP6}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* <Flex justifyContent='center'>
        <a href='https://mint.genomes.io'>
          <Flex
            py={4}
            px={12}
            border='1px'
            borderColor='#5B01AA'
            borderRadius='30px'
            alignItems='center'
            justifyContent='center'
            color='#5B01AA'
            cursor='pointer'
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            _hover={{
              bgColor: "#7D4BE0",
              color: "#fff",
              borderColor: "#5B01AA",
            }}
          >
            <Text textAlign='center' fontSize='18px' fontFamily='Helvetica-Bd'>
              Mint Now
            </Text>
          </Flex>
        </a>
      </Flex> */}
      <Fade in={hover}>
        <Text
          textAlign='center'
          fontFamily='Helvetica-Lt'
          color='#5B01AA'
          fontSize='16px'
          mt={2}
        >
          You will now be redirected to the Mint page <br /> to Mint the NFT
        </Text>
      </Fade>
      <Flex
        ref={ref1}
        // ml={isMobileDevice ? "" : 20}
        mt={10}
        flexDirection='column'
      >
        <Flex
          px={isMobileDevice ? 10 : undefined}
          justifyContent={isMobileDevice ? "flex-start" : "flex-start"}
        >
          <Text
            mb={10}
            className={isMobileDevice ? "mobile-heading" : "heading"}
            fontFamily='Helvetica-Bd'
            fontSize={isMobileDevice ? "40px" : "80px"}
            textAlign={isMobileDevice ? "center" : undefined}
          >
            ROAD
            <span className='text-nft'>MAP</span>
          </Text>
        </Flex>
        <Flex flexDirection={isSmallDevice ? "row" : "column"}>
          <Img
            mx={isSmallDevice ? undefined : isMobileDevice ? 5 : undefined}
            mb={10}
            src={isSmallDevice ? MOBILEROADMAP : ROADMAP}
          />
          <Flex w={isSmallDevice ? "100%" : undefined} justifyContent='center'>
            <Flex
              flex={isSmallDevice ? undefined : "1"}
              fontFamily='Helvetica-Bd'
              justifyContent='space-between'
              flexDirection={isSmallDevice ? "column" : undefined}
              alignItems={isSmallDevice ? "center" : undefined}
              w={isSmallDevice ? "100%" : ""}
            >
              <Flex
                mb={isSmallDevice ? 10 : ""}
                flex='0.3'
                flexDirection='column'
                w={isSmallDevice ? "100%" : undefined}
              >
                <Text
                  textAlign={isSmallDevice ? "center" : undefined}
                  // fontSize='12px'
                >
                  PHASE 1
                </Text>
                <Flex justifyContent={isSmallDevice ? "center" : ""}>
                  <Text mb={isSmallDevice ? 2 : 5} color='#4E00A8'>
                    February ‘22
                  </Text>
                </Flex>
                <Text
                  display={isSmallDevice ? undefined : "none"}
                  fontFamily='Helvetica-Bd'
                  mx={16}
                  mt={3}
                >
                  MINT
                </Text>
                <Flex mx={16}>
                  <ul
                    style={{
                      fontSize: "15px",
                      fontFamily: "Helvetica-Roman",
                      display: isSmallDevice ? undefined : "none",
                      marginBottom: "30px",
                    }}
                  >
                    <li>Artistic design</li>
                    <li>
                      Season 1 Mint (February 28th):{" "}
                      <span style={{ fontFamily: "Helvetica-Bold" }}>
                        Geneticats
                      </span>
                    </li>
                    <li>WGS Kits redeemable</li>
                    <li>GENE/GNOME claim traits redeemable</li>
                  </ul>
                </Flex>
              </Flex>

              <Flex
                mb={isSmallDevice ? 10 : ""}
                flex='0.3'
                flexDirection='column'
              >
                <Text
                  textAlign={isSmallDevice ? "center" : undefined}
                  // fontSize='12px'
                >
                  PHASE 2
                </Text>
                <Flex justifyContent={isSmallDevice ? "center" : ""}>
                  <Text mb={isSmallDevice ? 2 : 5} color='#4E00A8'>
                    March - July 22'
                  </Text>
                </Flex>
                <Text
                  display={isSmallDevice ? undefined : "none"}
                  fontFamily='Helvetica-Bd'
                  mx={16}
                >
                  LOGISTICS & SEQUENCING
                </Text>
                <Flex display={isSmallDevice ? undefined : "none"} mx={16}>
                  <ul
                    style={{
                      fontSize: "15px",
                      fontFamily: "Helvetica-Roman",
                      display: isSmallDevice ? undefined : "none",
                      marginBottom: "30px",
                    }}
                  >
                    <li>
                      We sequence your entire genome, then upload the genome
                      into your vault. (Lead times are 14-16 weeks for this
                      volume of genomes)
                    </li>
                  </ul>
                </Flex>
              </Flex>
              <Flex flex='0.3' flexDirection='column'>
                <Text
                  textAlign={isSmallDevice ? "center" : undefined}
                  // fontSize='12px'
                >
                  PHASE 3
                </Text>
                <Flex justifyContent={isSmallDevice ? "center" : ""}>
                  <Text mb={isSmallDevice ? 2 : 5} color='#4E00A8'>
                    July - August 22'
                  </Text>
                </Flex>
                <Text
                  display={isSmallDevice ? undefined : "none"}
                  // textAlign='center'
                  mx={16}
                  fontFamily='Helvetica-Bd'
                  mb={3}
                >
                  HEALTH INSIGHTS + <br /> GENERATIVE GENOMIC NFT’S
                </Text>
                <Flex display={isSmallDevice ? undefined : "none"} mx={16}>
                  <ul
                    style={{ fontSize: "15px", fontFamily: "Helvetica-Roman" }}
                  >
                    <li style={{ marginBottom: "10px" }}>
                      Your genome is vaulted under your self-custody. GenomesDAO
                      platform gives genomic insights on (live now): carrier
                      status, ancestry, and health risk. More tests added to the
                      platform.
                    </li>
                    <li>
                      Private Mint (free) of 1:1 generative NFT based on queries
                      from your genome (will occur later in the year).
                    </li>
                  </ul>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            display={isSmallDevice ? "none" : undefined}
            flex='1'
            justifyContent='space-between'
          >
            <Flex
              borderRight='1px'
              height='50%'
              borderColor='#DCDCDC'
              flex='0.3'
              flexDirection='column'
            >
              <Text fontFamily='Helvetica-Bd'>MINT</Text>
              <ul style={{ fontSize: "12px", fontFamily: "Helvetica-Roman" }}>
                <li
                  style={{
                    fontSize: "28px",
                    color: "#C8BEE6",
                    marginLeft: "10px",
                  }}
                >
                  <p style={{ fontSize: "15px", color: "black" }}>
                    Artistic design
                  </p>
                </li>

                <li
                  style={{
                    fontSize: "28px",
                    color: "#C8BEE6",
                    marginLeft: "10px",
                  }}
                >
                  <p style={{ fontSize: "15px", color: "black" }}>
                    Season 1 Mint (February 28th):{" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>
                      Geneticats
                    </span>
                  </p>
                </li>
                <li
                  style={{
                    fontSize: "28px",
                    color: "#C8BEE6",
                    marginLeft: "10px",
                  }}
                >
                  <p style={{ fontSize: "15px", color: "black" }}>
                    WGS Kits redeemable
                  </p>
                </li>

                <li
                  style={{
                    fontSize: "28px",
                    color: "#C8BEE6",
                    marginLeft: "10px",
                  }}
                >
                  <p style={{ fontSize: "15px", color: "black" }}>
                    {" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>
                      GENE/GNOME
                    </span>{" "}
                    claim traits redeemable
                  </p>
                </li>
              </ul>
            </Flex>
            <Flex
              borderRight='1px'
              height='160px'
              borderColor='#DCDCDC'
              flex='0.3'
              flexDirection='column'
            >
              <Text fontFamily='Helvetica-Bd'> LOGISTICS & SEQUENCING</Text>

              <ul style={{ fontSize: "15px", fontFamily: "Helvetica-Roman" }}>
                <li
                  style={{
                    fontSize: "28px",
                    color: "#C8BEE6",
                    // marginLeft: "10px",
                  }}
                >
                  <p style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ fontWeight: "bold" }}>
                      We sequence your entire genome,
                    </span>{" "}
                    then upload the genome into your vault. (Lead times are
                    12-14 weeks for this volume of genomes)
                  </p>
                </li>
              </ul>
              {/* <Text w='250px' fontSize='15px' fontFamily='Helvetica-Roman'>
                <span style={{ fontWeight: "bold" }}>
                  We sequence your entire genome,
                </span>{" "}
                then upload the genome into your vault. (Lead times are 12-14
                weeks for this volume of genomes)
              </Text> */}
            </Flex>
            <Flex flex='0.3' flexDirection='column'>
              <Text fontFamily='Helvetica-Bd'>
                HEALTH INSIGHTS + <br /> GENERATIVE GENOMIC NFT’S
              </Text>
              <ul style={{ fontSize: "12px", fontFamily: "Helvetica-Roman" }}>
                <li
                  style={{
                    fontSize: "28px",
                    color: "#C8BEE6",
                    marginLeft: "10px",
                  }}
                >
                  <p style={{ fontSize: "15px", color: "black" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Your genome is vaulted
                    </span>{" "}
                    under your self-custody. GenomesDAO platform gives genomic
                    insights on (live now):{" "}
                    <span style={{ fontWeight: "bold" }}>
                      carrier status, ancestry, and health risk. More tests
                      added to the platform.
                    </span>
                  </p>
                </li>
                <li
                  style={{
                    fontSize: "28px",
                    color: "#C8BEE6",
                    marginLeft: "10px",
                  }}
                >
                  <p style={{ fontSize: "15px", color: "black" }}>
                    {" "}
                    <span style={{ fontFamily: "Helvetica-Bold" }}>
                      Private Mint
                    </span>{" "}
                    (free) of 1:1 generative NFT based on queries from your
                    genome (will occur later in the year).
                  </p>
                </li>
              </ul>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
});

export default Competition;
