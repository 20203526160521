import './App.css';
import Nft from './Nft';
import { Route, Routes, HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <HashRouter>
      <ToastContainer />
      <Routes>
        <Route exact strict path="/" element={<Nft />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
