import React, { forwardRef, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Image,
  useMediaQuery,
  Img,
  Fade,
} from "@chakra-ui/react";
import FIRSTIMAGE from "../../asset/1Img.png";
import POWERED from "../../asset/vault2.png";
import GENERATIVE from "../../asset/generative.png";
import PFP from "../../asset/pfp.png";
import BOX from "../../asset/includedimg.svg";

import "./Bundles.css";

const Bundles = forwardRef((props, ref) => {
  const [isMobileDevice] = useMediaQuery("(max-width: 991px)");
  const [isSmallScreen] = useMediaQuery("(max-width: 1500px)");
  const [isNotMobile] = useMediaQuery("(min-width: 991px)");
  const [hover, setHover] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);

  return (
    <div>
      <Flex
        // pt={isMobileDevice ? 20 : 20}
        id='number1s'
        ref={ref}
        mx={isMobileDevice ? "" : isSmallScreen && isNotMobile ? 40 : 80}
        mt={isMobileDevice ? 10 : 40}
        mb={isMobileDevice ? 20 : ""}
      >
        <Flex
          mx={isMobileDevice ? 10 : undefined}
          w='100%'
          alignItems='center'
          justifyContent='space-between'
        >
          {isMobileDevice ? (
            <Text
              fontSize='40px'
              // textAlign='center'
              className='mobile-heading'
              fontFamily='Helvetica-BdLt'
              lineHeight='2.5rem'
            >
              WHAT'S <br /> INCLUDED <br /> WITH EVERY
              <br />
              <span className={"text-nft"}> NFT?</span>
            </Text>
          ) : (
            <Text
              fontSize={isMobileDevice ? "25px" : "80px"}
              textAlign={isMobileDevice ? "center" : ""}
              className={isMobileDevice ? "mobile-heading" : "heading"}
              fontFamily='Helvetica-BdLt'
              // lineHeight='4rem'
            >
              WHAT'S INCLUDED <br /> WITH EVERY
              <span className={"text-nft"}> NFT?</span>
            </Text>
          )}
          <Flex mr={isMobileDevice ? "" : 10}>
            <Image w='80px' src={BOX} />
          </Flex>
        </Flex>
      </Flex>

      <Flex
        mx={isMobileDevice ? "" : isSmallScreen && isNotMobile ? 40 : 80}
        mt={isMobileDevice ? "" : 40}
        flexDirection='column'
      >
        <Box>
          <Flex flexDirection='column'>
            <Flex w='100%'>
              <Flex
                w='100%'
                flex={isMobileDevice ? "" : "1"}
                flexDirection={isMobileDevice ? "column" : undefined}
                justifyContent={isMobileDevice ? undefined : "space-between"}
              >
                <Flex mb={10} flex='0.5' flexDirection='column'>
                  <Flex w='100%' justifyContent='center'>
                    <Img
                      display={isMobileDevice ? undefined : "none"}
                      // mt={6}
                      w='450px'
                      src={FIRSTIMAGE}
                    />
                  </Flex>
                  <Flex
                    w={isMobileDevice ? "100%" : ""}
                    justifyContent={isMobileDevice ? "flex-start" : ""}
                    mx={isMobileDevice ? 10 : undefined}
                  >
                    {isMobileDevice ? (
                      <Text
                        fontFamily='Helvetica-BDLt'
                        fontSize='25px'
                        color='#5B01AA'
                      >
                        WHOLE GENOME <br /> SEQUENCING KIT
                      </Text>
                    ) : (
                      <Text
                        fontFamily='Helvetica-BDLt'
                        fontSize={"27px"}
                        color='#5B01AA'
                      >
                        WHOLE GENOME SEQUENCING KIT
                      </Text>
                    )}
                  </Flex>

                  <Flex
                    fontFamily='Helvetica-Lt'
                    // color='#fff'
                    mt={5}
                    mx={isMobileDevice ? 10 : ""}
                    flexDirection='column'
                    w={isMobileDevice ? "" : "100%"}
                    fontSize={isMobileDevice ? "" : "16px"}
                    // p={3}
                  >
                    <Text mb={10}>
                      <Text fontFamily='Helvetica-Bold' fontWeight='bold'>
                        Your privacy, security, and trust are important to us.
                      </Text>
                      <br />
                      That's why{" "}
                      <b style={{ fontFamily: "Helvetica-Bd" }}>
                        GenomesDAO
                      </b>{" "}
                      partnered with world-class sequencing partner Nebula
                      Genomics. We sequenced your whole genome to a clinical
                      grade, decoding 100% of your DNA. Other providers like
                      23&Me or Ancestry only sequence 1-2% of your genome.
                    </Text>
                    <Text mb={10}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Helvetica-Bd",
                        }}
                      >
                        How it works:
                      </span>{" "}
                      <br /> We send you the whole genome sequencing kit (can be
                      sent anonymously to a PO box, workplace, etc.). You
                      receive the kit, swab your cheek, then send it back to our
                      HQ or directly to the lab (depending on your region).
                      Nebula sequences your genome, then securely uploads it
                      into your self-custody Genome Vault.
                    </Text>
                    <Text>
                      You got{" "}
                      <a
                        href='https://slangit.com/meaning/rugged'
                        target='_blank'
                        style={{ textDecoration: "underline", color: "blue" }}
                      >
                        rugged
                      </a>{" "}
                      on your genomic data if you've ever done a 23&Me or
                      Ancestry test. These companies genotype your DNA, then
                      they allow that data to be sold to other 3rd parties. When
                      your data gets sold, you don't share in the upside. All
                      the revenue generated from selling YOUR DNA goes to the
                      shareholders of these companies, not you! Rugged!!
                    </Text>
                  </Flex>
                  {/* <Flex
                    w={isMobileDevice ? "100%" : ""}
                    justifyContent={isMobileDevice ? "center" : ""}
                  >
                    <Flex
                      mt={5}
                      justifyContent={isMobileDevice ? "center" : "flex-start"}
                      flexDirection='column'
                    >
                      <a href='https://mint.genomes.io'>
                        <Flex
                          w={isMobileDevice ? "100%" : "80%"}
                          py={4}
                          px={10}
                          border='1px'
                          borderColor='#5B01AA'
                          borderRadius='30px'
                          alignItems='center'
                          justifyContent='center'
                          color='#5B01AA'
                          cursor='pointer'
                          onMouseEnter={() => setHover(true)}
                          onMouseLeave={() => setHover(false)}
                          _hover={{
                            bgColor: "#7D4BE0",
                            color: "#fff",
                            borderColor: "#5B01AA",
                          }}
                        >
                          <Text
                            textAlign='center'
                            fontSize='18px'
                            fontFamily='Helvetica-Bd'
                          >
                            Mint Now
                          </Text>
                        </Flex>
                      </a>

                      <Fade in={hover}>
                        <Text
                          textAlign='center'
                          fontFamily='Helvetica-Lt'
                          color='#5B01AA'
                          fontSize='16px'
                          mt={2}
                          display={isMobileDevice ? "none" : ""}
                        >
                          You will now be redirected to the Mint <br />{" "}
                          <span style={{ textAlign: "center" }}>
                            page to Mint the NFT
                          </span>
                        </Text>
                      </Fade>
                    </Flex>
                  </Flex> */}
                </Flex>
                <Flex flex='0.5'>
                  <Img
                    display={isMobileDevice ? "none" : undefined}
                    mt={6}
                    w='100%'
                    src={FIRSTIMAGE}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex mb={20} w='100%'>
              <Flex
                w='100%'
                flexDirection={isMobileDevice ? "column" : undefined}
                // alignItems='center'
                justifyContent='space-between'
                alignItems='center'
                flex='1'
              >
                <Flex flex='0.5'>
                  <Img
                    display={isMobileDevice ? "none" : undefined}
                    w='400px'
                    src={POWERED}
                  />
                </Flex>
                <Flex flex='0.5' flexDirection='column'>
                  <Flex justifyContent='center'>
                    <Img
                      display={!isMobileDevice ? "none" : undefined}
                      w='450px'
                      src={POWERED}
                    />
                  </Flex>
                  <Flex mx={isMobileDevice ? 10 : ""}>
                    {isMobileDevice ? (
                      <Text
                        fontFamily='Helvetica-BDLt'
                        fontSize='25px'
                        color='#5B01AA'
                        mt={20}
                      >
                        GENOME MINING VAULT
                      </Text>
                    ) : (
                      <Text
                        fontFamily='Helvetica-BDLt'
                        fontSize={"27px"}
                        color='#5B01AA'
                      >
                        GENOME MINING VAULT
                      </Text>
                    )}
                  </Flex>

                  <Flex
                    fontFamily='Helvetica-Lt'
                    // color='#fff'
                    mx={isMobileDevice ? 10 : ""}
                    mt={5}
                    // ml={5}
                    flexDirection='column'
                    w={isMobileDevice ? "" : "600px"}
                    fontSize={isMobileDevice ? "" : "16px"}
                    // p={3}
                  >
                    <Text mb={10}>
                      <Text fontFamily='Helvetica-Bold' fontWeight='bold'>
                        Bulletproof.
                      </Text>
                      <br />
                      Your precious DNA is protected by an{" "}
                      <span style={{ fontFamily: "Helvetica-Bd" }}>
                        AMD-SEV ES vault
                      </span>
                      . These vaults are the bleeding edge of encryption
                      technology.
                    </Text>
                    <Text mb={10}>
                      <span style={{ fontFamily: "Helvetica-Bd" }}>
                        Powered by a mobile{" "}
                      </span>{" "}
                      <span
                        style={{ fontFamily: "Helvetica-Bd", color: "#3B00E1" }}
                      >
                        blockchain wallet
                      </span>
                      , only you can control access to your vault, very much
                      like a ledger or metamask wallet. This breakthrough in
                      encryption technology enables you to truly have full
                      custody over your entire genome. No one can access your
                      DNA vault without your explicit permission - not even us.
                    </Text>
                    <Text>
                      Our vaults give you the ability to securely “mine your
                      genome”. The GenomesDAO platform currently allows you to
                      run ancestry and rare disease reports on your genome, we
                      plan to add more reports over time. Additionally, you can
                      safely monetize your genome to earn GENE tokens by
                      accepting or rejecting genomic query requests from
                      pharmaceutical companies or research organizations. By
                      accepting a query, the answer to the query is executed
                      inside the vault; your raw genomic data will never be
                      exposed.{" "}
                      <span style={{ fontFamily: "Helvetica-Bd" }}>
                        As we like to say, “not your keys, not your genome.”
                      </span>
                    </Text>
                    {/* <Flex
                      w={isMobileDevice ? "100%" : ""}
                      justifyContent={isMobileDevice ? "center" : ""}
                      // ml={isMobileDevice ? 5 : ""}
                    >
                      <Flex
                        mt={5}
                        justifyContent={
                          isMobileDevice ? "center" : "flex-start"
                        }
                        flexDirection='column'
                      >
                        <a href='https://mint.genomes.io' target='_blank'>
                          <Flex
                            w={isMobileDevice ? "100%" : "80%"}
                            py={4}
                            px={10}
                            border='1px'
                            borderColor='#5B01AA'
                            borderRadius='30px'
                            alignItems='center'
                            justifyContent='center'
                            color='#5B01AA'
                            cursor='pointer'
                            onMouseEnter={() => setHover2(true)}
                            onMouseLeave={() => setHover2(false)}
                            _hover={{
                              bgColor: "#7D4BE0",
                              color: "#fff",
                              borderColor: "#5B01AA",
                            }}
                          >
                            <Text
                              textAlign='center'
                              fontSize='18px'
                              fontFamily='Helvetica-Bd'
                            >
                              Mint Now
                            </Text>
                          </Flex>
                        </a>

                        <Fade in={hover2}>
                          <Text
                            textAlign='center'
                            fontFamily='Helvetica-Lt'
                            color='#5B01AA'
                            fontSize='16px'
                            mt={2}
                            display={isMobileDevice ? "none" : ""}
                          >
                            You will now be redirected to the Mint <br /> page
                            to Mint the NFT
                          </Text>
                        </Fade>
                      </Flex>
                    </Flex> */}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Flex w='100%'>
              <Flex
                w='100%'
                flexDirection={isMobileDevice ? "column" : undefined}
                // alignItems='center'
                justifyContent='space-between'
              >
                <Flex justifyContent='center'>
                  <Img
                    display={!isMobileDevice ? "none" : undefined}
                    mt={6}
                    w='450px'
                    src={GENERATIVE}
                  />
                </Flex>
                <Flex flexDirection='column'>
                  <Flex mx={isMobileDevice ? 10 : ""}>
                    {isMobileDevice ? (
                      <Text
                        fontFamily='Helvetica-BDLt'
                        fontSize='25px'
                        color='#5B01AA'
                        mt={20}
                      >
                        1:1 GENERATIVE ART <br /> (MADE FROM YOU)
                      </Text>
                    ) : (
                      <Text
                        fontFamily='Helvetica-BDLt'
                        fontSize={"27px"}
                        color='#5B01AA'
                      >
                        1:1 GENERATIVE ART (MADE FROM YOU)
                      </Text>
                    )}
                  </Flex>

                  <Flex
                    fontFamily='Helvetica-Lt'
                    mt={5}
                    mx={isMobileDevice ? 10 : ""}
                    flexDirection='column'
                    w={isMobileDevice ? "" : "550px"}
                    fontSize={isMobileDevice ? "" : "16px"}
                  >
                    <Text mb={10}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Helvetica-Bold",
                        }}
                      >
                        An industry first, a new kind of NFT. <br />
                        <br />
                      </span>
                      A generative, contemporary art piece quite literally made
                      from you,{" "}
                      <span style={{ fontFamily: "Helvetica-Bold" }}>
                        immortalized forever
                      </span>{" "}
                      on the blockchain. After you’ve vaulted your genome, we
                      ask permission to take a few small queries of your genome
                      as the mathematical inputs for this piece of generative
                      art.
                    </Text>

                    <Text>
                      If a loved one gifted you an engraved piece of jewelry,
                      would you ever sell it? This might disappoint the NFT
                      flippers, but we don't care about the floor price. In
                      fact, we want this to be the first NFT you never sell.
                      Why? The inputs to create this NFT are generated from the
                      same nitrogenous bases that make up your genetic code.
                      We're hoping you keep this NFT forever and pass it on to
                      your grandchildren as a family heirloom.
                    </Text>
                    {/* <Flex
                      w={isMobileDevice ? "100%" : ""}
                      justifyContent={isMobileDevice ? "center" : ""}
                    >
                      <Flex
                        mt={5}
                        justifyContent={
                          isMobileDevice ? "center" : "flex-start"
                        }
                        flexDirection='column'
                      >
                        <a href='https://mint.genomes.io'>
                          <Flex
                            w={isMobileDevice ? "100%" : "80%"}
                            py={4}
                            px={10}
                            border='1px'
                            borderColor='#5B01AA'
                            borderRadius='30px'
                            alignItems='center'
                            justifyContent='center'
                            color='#5B01AA'
                            cursor='pointer'
                            onMouseEnter={() => setHover3(true)}
                            onMouseLeave={() => setHover3(false)}
                            _hover={{
                              bgColor: "#7D4BE0",
                              color: "#fff",
                              borderColor: "#5B01AA",
                            }}
                          >
                            <Text
                              textAlign='center'
                              fontSize='18px'
                              fontFamily='Helvetica-Bd'
                            >
                              Mint Now
                            </Text>
                          </Flex>
                        </a>

                        <Fade in={hover3}>
                          <Text
                            textAlign='center'
                            fontFamily='Helvetica-Lt'
                            color='#5B01AA'
                            fontSize='16px'
                            mt={2}
                            display={isMobileDevice ? "none" : ""}
                          >
                            You will now be redirected to the Mint <br />{" "}
                            <span style={{ textAlign: "center" }}>
                              page to mint the NFT
                            </span>
                          </Text>
                        </Fade>
                      </Flex>
                    </Flex> */}
                  </Flex>
                </Flex>
                <Img
                  display={isMobileDevice ? "none" : undefined}
                  mt={6}
                  w='550px'
                  src={GENERATIVE}
                />
              </Flex>
            </Flex>
            <Flex w='100%'>
              <Flex
                w='100%'
                flexDirection={isMobileDevice ? "column" : undefined}
                alignItems='center'
                justifyContent='space-between'
              >
                <Flex mt={isMobileDevice ? 20 : ""} justifyContent='center'>
                  <Img
                    display={!isMobileDevice ? "none" : undefined}
                    w='450px'
                    src={PFP}
                  />
                </Flex>
                <Flex w='100%' justifyContent='flex-start'>
                  <Img
                    display={isMobileDevice ? "none" : undefined}
                    w='450px'
                    src={PFP}
                  />
                </Flex>
                <Flex mt={isMobileDevice ? -30 : ""} flexDirection='column'>
                  <Flex mx={isMobileDevice ? 10 : ""}>
                    {isMobileDevice ? (
                      <Text
                        fontFamily='Helvetica-BDLt'
                        fontSize='25px'
                        color='#5B01AA'
                        mt={20}
                      >
                        GENETICATS NFT
                      </Text>
                    ) : (
                      <Text
                        fontFamily='Helvetica-BDLt'
                        fontSize={"27px"}
                        color='#5B01AA'
                      >
                        GENETICATS NFT
                      </Text>
                    )}
                  </Flex>

                  <Flex
                    fontFamily='Helvetica-Lt'
                    // color='#fff'
                    mt={5}
                    // ml={5}
                    flexDirection='column'
                    w={isMobileDevice ? "" : "550px"}
                    fontSize={isMobileDevice ? "" : "16px"}
                    // ml={isMobileDevice ? 10 : ""}
                    mx={isMobileDevice ? 10 : undefined}
                    // p={3}
                  >
                    <Text mb={10}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Helvetica-Bold",
                        }}
                      >
                        Meow. <br />
                        <br />
                      </span>
                      Cats have super fun genomics.{" "}
                      <span style={{ fontFamily: "Helvetica-Bd" }}>
                        Did you know that only one in every 3,000 calico cats is
                        born a male?
                      </span>{" "}
                      Show that you're part of the{" "}
                      <span
                        style={{ fontFamily: "Helvetica-Bd", color: "#5B01AA" }}
                      >
                        GenomesDAO
                      </span>{" "}
                      community with GenomesDAO’s{" "}
                      <span style={{ fontFamily: "Helvetica-Bd" }}>
                        first Cat NFT.
                      </span>{" "}
                    </Text>
                    <Text mb={10}>
                      Each Geneticat is licensed under a CC0 license, meaning
                      you can do whatever you want with it. Put it on a t-shirt,
                      sell it, become the Cat, dominate the world– that’s all
                      fair game.
                    </Text>
                    {/* <Flex
                      w={isMobileDevice ? "100%" : ""}
                      justifyContent={isMobileDevice ? "center" : ""}
                      mb={isMobileDevice ? 10 : ""}
                    >
                      <Flex
                        mt={5}
                        justifyContent={
                          isMobileDevice ? "center" : "flex-start"
                        }
                        flexDirection='column'
                      >
                        <a href='https://mint.genomes.io'>
                          <Flex
                            w={isMobileDevice ? "100%" : "80%"}
                            py={4}
                            px={10}
                            border='1px'
                            borderColor='#5B01AA'
                            borderRadius='30px'
                            alignItems='center'
                            justifyContent='center'
                            color='#5B01AA'
                            cursor='pointer'
                            onMouseEnter={() => setHover4(true)}
                            onMouseLeave={() => setHover4(false)}
                            _hover={{
                              bgColor: "#7D4BE0",
                              color: "#fff",
                              borderColor: "#5B01AA",
                            }}
                          >
                            <Text
                              textAlign='center'
                              fontSize='18px'
                              fontFamily='Helvetica-Bd'
                            >
                              Mint Now
                            </Text>
                          </Flex>
                        </a>

                        <Fade in={hover4}>
                          <Text
                            textAlign='center'
                            fontFamily='Helvetica-Lt'
                            color='#5B01AA'
                            fontSize='16px'
                            mt={2}
                            display={isMobileDevice ? "none" : ""}
                          >
                            You will now be redirected to the Mint <br />{" "}
                            <span style={{ textAlign: "center" }}>
                              page to Mint the NFT
                            </span>
                          </Text>
                        </Fade>
                      </Flex>
                    </Flex> */}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </div>
  );
});

export default Bundles;
